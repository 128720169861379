
  import { mapGetters } from 'vuex'

  import HidableWrapper from '@/components/search/filters/HidableWrapper'

  import EventBus, { BusEvents } from '@/services/eventBus/EventBus'
  import NLink from '~/components/utils/NLink'
  import NavigationMapper from '~/configurations/mixins/NavigationMapper'
  import NavigationPage from '~/database/models/NavigationPage'

  export default {
    name: 'FooterComponent',
    components: { NLink, HidableWrapper },
    inject: ['getLoc'],
    mixins: [NavigationMapper],
    data () {
      return {
        hidableWrappers: (process.client ? window.innerWidth : 1200) < 768,
        currentYear: (new Date()).getFullYear(),
        sections: {
          aboutUs: false,
          shop: false,
          bonus: false
        }
      }
    },
    computed: {
      ...mapGetters({
        getMenu: 'menu/getMenu'
      }),
      giftIdeasPage(vm) {
        const page = vm.getMenu('gift-ideas')
        return page ? new NavigationPage(page) : null
      },
    },
    mounted () {
      EventBus.$on(BusEvents.app.windowResize, this.windowSizeChanged)
    },
    beforeDestroy () {
      EventBus.$off(BusEvents.app.windowResize, this.windowSizeChanged)
    },
    methods: {
      windowSizeChanged ({ width, height }) {
        const isMobile = width < 768
        this.hidableWrappers = isMobile
        this.sections.aboutUs = !isMobile
        this.sections.shop = !isMobile
        this.sections.bonus = !isMobile
      }
    }
  }
