
  import Tooltip from '~/components/general/elements/Tooltip/Tooltip'

  export default {
    name: 'HidableWrapper',
    functional: true,
    components: { Tooltip },
    props: {
      title: {
        type: String
      },
      show: {
        type: Boolean
      },
      count: {
        type: Number,
        default: 0
      },
      tooltipContent: {
        type: String
      },
      tooltipBottom: Boolean,
      isTogglable: {
        type: Boolean,
        default: true
      }
    },
    toggle (listeners, show) {
      if ('update:show' in listeners) {
        listeners['update:show'](!show)
      }
    }
  }
