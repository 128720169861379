
  export default {
    name: 'Tooltip',
    components: {},
    props: {
      title: {
        type: String,
        default: null
      },
      content: {
        type: String,
        default: null
      },
      disabled: Boolean,
      forced: Boolean,
      light: Boolean,
      bottom: Boolean,
      large: Boolean,
      position: {
        type: String,
        default: 'right',
        validator: value => {
          return ['left', 'right'].includes(value)
        }
      }
    },
    data () {
      return {
        show: false
      }
    },
    computed: {
      computedVisibility(vm) {
        return vm.forced || vm.show
      }
    },
    methods: {
      toggleShow() {
        if(window.matchMedia( "(hover: none)" ).matches) {
          this.show = !this.show
        }
      }
    }
  }
